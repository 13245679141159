import React, { useEffect, useRef, useState } from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'
import { useLocation } from '@reach/router'
import { DataLayerProps, useDataLayer } from 'src/hooks/useDataLayer'
import { Post } from 'src/types/Post'

type TextProps = {
  pageContext: Post;
  slug: string;
  title?: string;
  text: string;
  scroll: number;
  activeSlug?: string;
  setActiveSlug: (value: string) => void;
}

const Text = ({ slug, title, text, scroll, activeSlug, setActiveSlug, pageContext }: TextProps) => {
  const [ eventSent, setEventSent ] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)
  const { sendDataLayer }: { sendDataLayer: (data: DataLayerProps) => void } = useDataLayer()
  const location = useLocation()

  useEffect(() => {
    if (title) {
      const offset = (textRef.current as HTMLDivElement).getBoundingClientRect().top - document.body.getBoundingClientRect().top
      if ((offset - 70) <= scroll) {
        activeSlug && setActiveSlug(slug)
      }
      if ((offset - 70) <= scroll && !eventSent) {
        setEventSent(true)
        sendDataLayer({
          event: 'ga_event_scroll_blog',
          params: {
            section: pageContext.title,
            sub_section: title,
            c_page: location.href,
          },
        })
      }
    }
  }, [ scroll ])

  return (
    <div ref={textRef} id={slug}>
      {title && <h2>{title}</h2>}
      <ScrollContainer innerRef={textRef}>
        <div className='table-content' dangerouslySetInnerHTML={{ __html: text }} />
      </ScrollContainer>
    </div>
  )
}

export default Text
