import React from 'react'
import Helmet from 'react-helmet'

interface IVideoObj {
  name: string;
  caption: string;
  thumbnailUrl: string[];
  uploadVideoDate: string;
  url: string;
}

type VideoProps = {
  data: IVideoObj;
}

const VideoStructuredData = ({ data }: VideoProps) => {
  const videoObjData = data
  console.log('VideoObjectData', videoObjData)
  const parsedData = {
    '@context': 'https://schema.org',
    '@type': 'VideoObject',
    name: videoObjData.name,
    description: videoObjData.caption,
    thumbnailUrl: videoObjData.thumbnailUrl,
    uploadDate: `${videoObjData.uploadVideoDate}T00:00:00.000Z`,
    contentUrl: videoObjData.url,
    embedUrl: videoObjData.url,
  }

  return (
    <Helmet>
      <script type='application/ld+json'>{`${JSON.stringify(parsedData)}`}</script>
    </Helmet>
  )
}

export default VideoStructuredData
