import React from 'react'
import Helmet from 'react-helmet'

// Types
import { PostSeo } from 'src/types/Post'

type HowToProps = {
  data: PostSeo;
}

interface IStep {
  name: string;
  text: string;
}

const HowToStructuredData = ({ data }: HowToProps) => {
  const howToObj = data.content

  const parsedData = {
    '@context': 'https://schema.org',
    '@type': 'HowTo',
    name: howToObj.title,
    description: howToObj.description,
    totalTime: howToObj.totalTime,
    step: [
      data.content.topics.map((item: IStep) => {
        return {
          '@type': 'HowToStep',
          name: item.name,
          text: item.text,
        }
      }),
    ],
  }

  return (
    <Helmet>
      <script type='application/ld+json'>{`${JSON.stringify(parsedData)}`}</script>
    </Helmet>
  )
}

export default HowToStructuredData
