import React from 'react'
import ArrowUp from 'inter-frontend-svgs/lib/v2/navigation/arrow-up'

// Styles
import ScrollButtonStyle from './style'
import { Theme } from 'src/types/Theme'

type ScrollButtonProps = {
  position: string;
  theme?: Theme;
}

const ScrollButton = ({ position, theme = 'pf' }: ScrollButtonProps) => {
  function handleClick () {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <ScrollButtonStyle position={position} onClick={handleClick} theme={theme}>
      <ArrowUp width={24} height={24} color={`${theme === 'pj' ? 'green--500' : 'orange--base'}`} />
    </ScrollButtonStyle>
  )
}

export default ScrollButton
