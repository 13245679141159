import styled from 'styled-components'

export const Btn = styled.a`
  background-color: #FF7A00;
  border: none;
  color: white;
  padding: 20px 30px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  font-family: 'Inter';
  font-weight: 600;
  border-radius: 10px;
  text-decoration: none;
  transition: 700ms;

  &:hover {
    opacity: 0.9;
    text-decoration: none;
    color: rgb(255, 255, 255);
  }
`
