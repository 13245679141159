import React, { MouseEvent, useEffect, useState, ChangeEvent } from 'react'
import { useLocation } from '@reach/router'
import slugify from 'slugify'
import { DataLayerProps, useDataLayer } from 'src/hooks/useDataLayer'
import { Post, PostText, PostVideo, PostTextImage, PostImage, PostSubText, PostFaq, PostCitation, PostButton } from 'src/types/Post'

import Icon from 'src/components/Icon'
import { QuickAccessStyle } from './style'

type postProp = {
  pageContext: Post;
  category: string;
  subCategory?: string;
}

type AllPostsTypes = | PostText | PostVideo | PostTextImage | PostImage | PostSubText | PostFaq | PostCitation | PostButton

const QuickAccess = (props: postProp) => {
  const [ activeSlug, setActiveSlug ] = useState<string>('')
  const [ stateSections, setSectionsState ] = useState<string>('opened')
  const { sendDataLayer }: { sendDataLayer: (data: DataLayerProps) => void } = useDataLayer()
  const location = useLocation()

  const quickAccessLinks = props.pageContext.content_blocks
    .filter((contentBlock: AllPostsTypes) => contentBlock.type !== 'button')
    .filter(
      (contentBlock: AllPostsTypes) =>
        (contentBlock as PostText | PostTextImage | PostFaq).content.title,
    )
    .map((contentBlock: PostText | PostTextImage | PostFaq) => ({
      title: (contentBlock as PostText | PostTextImage | PostFaq).content.title,
      slug: slugify(
        (contentBlock as PostText | PostTextImage | PostFaq).content.title,
        { lower: true, strict: true },
      ),
    }))

  const dataLayerExtract = (evt: ChangeEvent<HTMLInputElement>) => {
    dataLayer.push({
      event: 'Acesso Rápido',
      topico: evt.target.innerText,
      nomeDoPost: props.pageContext.title,
    })
  }

  function handleScroll (evt: MouseEvent<HTMLAnchorElement>) {
    evt.preventDefault()
    const slug = evt.currentTarget.href.split('#')[1]
    setActiveSlug(slug)
    const elementRect = (document.querySelector(`#${slug}`) as HTMLElement).getBoundingClientRect()
    const bodyRect = document.body.getBoundingClientRect()
    const offset = elementRect.top - bodyRect.top - 105

    window.scrollTo({
      top: offset,
      behavior: 'smooth',
    })

    dataLayerExtract(evt)

    sendDataLayer({
      event: 'ga_event_body_blog',
      params: {
        section: props.pageContext.title,
        sub_section: 'Acesso rápido',
        element_action: 'click scroll',
        element_name: props.pageContext.slug,
        c_page: location.href,
        redirect_url: 'null',
        segment: props?.category,
        sub_segment: props?.subCategory || 'null',
      },
    })
  }

  function handleOpenClose () {
    if (stateSections === 'opened') {
      setSectionsState('closed')
    } else {
      setSectionsState('opened')
    }
  }

  useEffect(() => {
    const firstContentWithTitle = props.pageContext.content_blocks.find((contentBlock: AllPostsTypes) =>
      (contentBlock as PostText | PostTextImage).content.title !== 'undefined')
    if (firstContentWithTitle.content.title) {
      const slug = slugify(firstContentWithTitle.content.title.replace(/\./g, ''), { lower: true })
      setActiveSlug(slug)
    }
  }, [])

  return (
    <>
      {quickAccessLinks.length > 0 && (
        <QuickAccessStyle className='bg-grayscale--100 pt-4 pb-1 mb-3 mt-lg-1'>
          <span className='titulo d-block fs-22 lh-25 fw-600 text-grayscale--500 mb-3 mx-3'>
            Acesso rápido
          </span>
          <nav className='mt-1'>
            <ul className={`list-unstyled ${stateSections}`}>
              {quickAccessLinks.map(
                (accessLink: { title: string; slug: string }) => (
                  accessLink.title.trim() &&
                    <li key={accessLink.slug}>
                      <a
                        onClick={(evt: MouseEvent<HTMLAnchorElement>) => handleScroll(evt)}
                        href={'#ir-para-' + `${accessLink.slug}`}
                        className={`fs-16 py-2 px-3 d-block text-decoration-none text-gray--800
                        ${'ir-para-' + accessLink.slug} ${'ir-para-' + accessLink.slug === activeSlug ? ' active' : ''}`}
                      >
                        {accessLink.title}
                      </a>
                    </li>
                ),
              )}
            </ul>
            <div className={`transition-div w-100 ${stateSections === 'closed' ? 'add-bg' : 'remove-bg'}`} />
            <div onClick={() => handleOpenClose()} className='w-100 d-flex justify-content-center align-items-center nav-button'>
              <div className={stateSections === 'opened' ? 'd-block' : 'd-none'}>
                <Icon width={30} height={30} directory='v2' icon='navigation/chevron-up' color='orange--base' />
              </div>
              <div className={stateSections === 'closed' ? 'd-block' : 'd-none'}>
                <Icon width={30} height={30} directory='v2' icon='navigation/chevron-down' color='orange--base' />
              </div>
            </div>
          </nav>
        </QuickAccessStyle>
      )}
    </>
  )
}

export default QuickAccess
