import React, { useMemo } from 'react'
import { Link } from 'gatsby'
import ChevronRight from 'inter-frontend-svgs/lib/v2/navigation/chevron-right'

import BreadcrumbStyle from './style'
import { Helmet } from 'react-helmet'

type BreadcrumbItem = {
  link?: string;
  text: string;
}

type BreadCrumb = {
  path: BreadcrumbItem[];
}

const Breadcrumb = ({ path }: BreadCrumb) => {
  const structuredDataString = useMemo(() => {
    return JSON.stringify({
      '@context': 'https://schema.org/',
      '@type': 'BreadcrumbList',
      itemListElement: path.map((item: BreadcrumbItem, index: number) => ({
        '@type': 'ListItem',
        position: index + 1,
        name: item.text,
        item: `${process.env.BASE_URL}${item.link}`,
      })),
    })
  }, [ path ])

  return (
    <>
      <Helmet>
        <script type='application/ld+json'>
          {structuredDataString}
        </script>
      </Helmet>
      <BreadcrumbStyle className='d-flex align-items-center'>
        {
          path.map((breadcrumItem: BreadcrumbItem, index: number) => (
            <span className='fs-14 lh-18 align-items-center' key={breadcrumItem.text}>
              {breadcrumItem.link ? (
                <Link className='text-grayscale--400' to={breadcrumItem.link.endsWith('/') ? breadcrumItem.link : `${breadcrumItem.link}/`}>{breadcrumItem.text}</Link>
              )
                : (
                  <span className='text-grayscale--400 fw-700'>{breadcrumItem.text}</span>
                )}
              { index < path.length - 1 && <ChevronRight width={17} height={17} color='grayscale--400' /> }
            </span>
          ))
        }
      </BreadcrumbStyle>
    </>
  )
}

export default Breadcrumb
