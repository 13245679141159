import React from 'react'

import PdfIcon from 'src/assets/images/icons/icon-pdf.svg'

import { BoxPdf } from './style'

type PdfFileProps = {
  name: string;
  description: string;
  file: {
    url: string;
  };
}

const PdfFile = ({ name, description, file }: PdfFileProps) => {
  return (
    <BoxPdf className='d-flex align-items-center'>
      <div className='col-2 col-md-1 col-lg-2 col-xl-1 text-center'>
        <img src={PdfIcon} alt='logo pdf' />
      </div>
      <div className='col-10 col-md-11 col-lg-10 col-xl-11 py-2 px-0 fs-14'>
        <a href={file.url} target='_blank' rel='noreferrer'>{name}</a>
        <p className='mb-0'>{description}</p>
      </div>
    </BoxPdf>
  )
}

export default PdfFile
