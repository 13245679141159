import styled from 'styled-components'
import { borderRounded } from 'src/styles/radius'
import { grayscale, orange } from 'src/styles/colors'

export const QuickAccessStyle = styled.div`
  position: relative;
  border-radius: ${borderRounded['3']};
  .closed {
    max-height: 150px;
    overflow: hidden;
    transition: max-height 0.35s ease-in-out;
  }

  .opened {
    max-height: 1000px;
    transition: max-height 0.35s ease-in-out;
    overflow: hidden;
  }

  .nav-button {
    height: 40px;
    cursor: pointer;
  }

  .transition-div {
    position: absolute;
    height: 40px;
    bottom: 40px;
  }

  .add-bg {
    background-color: ${grayscale[100]};
    opacity: 0.6;
    transition: opacity 0.35s ease-in-out;
  }

  .remove-bg {
    background-color: ${grayscale[100]};
    opacity: 0;
    transition: opacity 0.35s ease-in-out;
    z-index: -1;
  }

  ul {
    margin-bottom: 0;

    a {

      &.active {
        color: ${orange.base};
        border-left: 3px solid ${orange.base};
        font-weight: 700;
      }

      &:hover {
        color: ${orange.base};
      }
    }
  }
  .titulo {
    font-family: 'Sora';
  }
`
