import React, { useState } from 'react'
import Modal from 'src/components/Modal'
import { ZoomButton, ZoomContainer } from 'src/components/ZoomableImage/style'
import Icon from 'src/components/Icon'

type Props = {
  zoomSrc: string;
  zoomAlt?: string;
  zoomMaxWidth: string;
  children: React.ReactNode;
}

const ZoomableImage = ({ zoomSrc, zoomAlt, zoomMaxWidth, children }: Props) => {
  const [ open, setOpen ] = useState(false)

  const handleClose = () => setOpen(false)

  const handleOpen = (e: React.MouseEvent) => {
    e.preventDefault()
    setOpen(true)
  }

  return (
    <>
      {open && (
        <Modal onClose={handleClose} onClickOutside={handleClose} showCloseButton width={zoomMaxWidth} noPadding>
          <img src={zoomSrc} alt={zoomAlt || ''} width={zoomMaxWidth} />
        </Modal>
      )}
      <ZoomContainer onClick={handleOpen}>
        {children}
        <ZoomButton>
          <Icon width={20} height={20} directory='v2' icon='navigation/search' color='black' />
        </ZoomButton>
      </ZoomContainer>
    </>
  )
}

export default ZoomableImage
