import styled from 'styled-components'

export const ZoomContainer = styled.div`
  position: relative;
  cursor: zoom-in;

  &:hover{
    button {
      background: rgba(255, 255, 255, .55);
    }
  }
`

export const ZoomButton = styled.button`
  padding: 10px;
  border-radius: 6px;
  background: rgba(255, 255, 255, .35);
  border: none;
  margin: 0;
  position: absolute;
  bottom: 5px;
  right: 5px;
`
