import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'

export const StyleAdd = styled.div`
  a {
   margin-bottom: 0 !important;   
   margin-right: 4px !important;

   @media (min-width: ${breakpoints.lg}) {
      margin-right: 8px !important;
    }
  } 
`
