import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'
import Arrow from 'inter-frontend-svgs/lib/v2/navigation/chevron-down.svg'

export const SumaryDetailsSection = styled.section`
  cursor: pointer;

  .summary {
    width: 100%;
    display: block;
    border-bottom: 0.5px solid rgba(140, 145, 169, 0.2);
    transition: 100ms all ease;

    &--open {
      width: 101%;
      margin-left: -0.5%;
      margin-bottom: 15px;
      background-color: white;

      @media (min-width: ${breakpoints.md}) {
        border: 0.5px solid rgba(140, 145, 169, 0.2);
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.1);
      }
    }

    &-content {
      padding: 20px 0;

      @media (min-width: ${breakpoints.md}) {
        padding: 20px;
        min-height: 100px;
      }

      .arrow {
        background: url(${Arrow}) no-repeat center center;
        height: 18px;
        width: 18px;
        background-repeat: no-repeat;
        transition: all 200ms;
        float: right;

        &--open{
          transform: rotate(-180deg);
        }
      }
    }
  }

  .details {
    display: block;
    border-top: none;
    overflow: hidden;
    transition: all 300ms ease-in-out;
    max-height: 0;

    &-content {
      transition: all 300ms ease-in-out;
      opacity: 0;
    }

    &--open {
      max-height: 5000px;
      border-top: solid 0.5px rgba(140, 145, 169, 0.2);

      .details-content {
        cursor: initial;
        opacity: 1; 
        padding: 20px 0;

        @media (min-width: ${breakpoints.md}) {
          padding: 30px;
        }
      }
    }
  }
`
