import styled, { css } from 'styled-components'
import { gray, grayscale, green, orange, red, white } from 'src/styles/colors'
import breakpoints from 'src/styles/breakpoints'

type FormStyledProps = {
  theme: 'pj' | 'pf' | 'neutral';
}

type NewslatterFormProps = {
  theme: 'pj' | 'pf' | 'neutral';
  $sentStatus: boolean;
}

export const Form = styled.form<FormStyledProps>`

  label {
    display: block;
  }

  input {
    background: ${white};
    border-radius: 8px;
    border: none;
    font-size: 14px;
    line-height: 20px;
    padding: 10px 15px;
    font-weight: 300;
    color: ${gray[600]};
    width: 100%;

    @media(min-width: ${breakpoints.lg}) {
      background: ${white};
    }

    &.hasError {
      border: 1px solid red;
    }
  }

  .field--label-error {
    font-size: 12px;
    color: red;
  }

  .custom-radio {

    .radio {
      &:not(:last-child) {
        border-bottom: 1px solid #E6E7F0;
      }
    }

    .radio > label{
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0;
      padding: 10px;
      font-size: 14px;
      position: relative;
      cursor: pointer;

      input {
        margin-right: 10px;
        opacity: 0;
      }

      .help {
        display: block;
        width: 20px;
        height: 20px;
        position: relative;
      }

      .help::before {
        display: block;
        position: absolute;
        content: '';
        width: 20px;
        height: 20px;
        border: 2px solid ${orange.base};
        border-radius: 50%;
        top: 2px;
        left: 0;
      }

      input:checked + .help::after {
        display: block;
        position: absolute;
        content: '';
        width: 12px;
        height: 12px;
        background-color: ${orange.base};
        border-radius: 50%;
        left: 4px;
        top: 6px;
        opacity: 1;
      }
    }
  }

  input {
    &::placeholder {
      color: ${grayscale[300]};
      font-weight: 700;
      font-size: 12px;
      line-height: 15px;
    }
  }

  .checkbox {

    label {
      position: relative;
      padding-left: 30px;
      margin-bottom: 0;

      &:before, &:after {
        display: block;
        position: absolute;
        content: '';
      }

      &:before {
        border: 2px solid ${white};

        border-radius: 3px;
        width: 20px;
        height: 20px;
        left: 0;
        top: 2px;
      }
    }

    input {
      width: auto;
      margin-right: 10px;
      opacity: 0;
      height: 0;

      &:disabled ~ label {
        opacity: 0.6;
      }

      &:checked ~ label {

        &:before {
          border: 2px solid ${white};
          background-color: ${({ theme }: FormStyledProps) => theme === 'pj' ? green[500] : orange.base};
        }

        &:after {
          left: 7px;
          top: 5px;
          width: 6px;
          height: 10px;
          border: solid ${white};
          border-width: 0 2px 2px 0;
          transform: rotate(45deg);
        }
      }
    }
  }
`

export const Msg = styled.div<FormStyledProps>`
  ${({ theme }: FormStyledProps) => {
    switch (theme) {
      case 'pj':
        return css`
          h4 {
            color: ${grayscale[500]} !important;
          }

          p {
            color: ${grayscale[500]} !important;
          }
        `
      case 'pf':
        return css`
          h4 {
            color: ${grayscale[500]} !important;
          }

          p {
            color: ${grayscale[500]} !important;
          }
        `
      default:
        return css`
          h4 {
            color: ${grayscale[500]} !important;
          }

          p {
            color: ${grayscale[500]} !important;
          }
        `
    }
  }};
`

export const NewsletterForm = styled.section<NewslatterFormProps>`
  padding: 24px;
  border-radius: 16px;

  h3 {
    font-size: 20px;
    line-height: 26px;
  }

  p {
    font-size: 16px;
    line-height: 20px;
  }

  label {
    font-size: 14px;
    line-height: 17px;
  }

  button {
    font-weight: 700 !important;
    &:disabled {
      font-weight: 700 !important;
      border: none !important;
      color: #FFFF !important;
    }
    border: none !important;
  }

  span {
    font-weight: 700;
    color: ${red.base} !important;
  }

  ${({ theme }: FormStyledProps) => {
    switch (theme) {
      case 'pj':
        return css`
          background-color: ${green[500]};
          h3, p, label, a {
            color: #FFFFFF;
          }
          button, .inverse {
            color: ${green[500]} !important;
          }
        `
      case 'pf':
        return css`
          background-color: ${orange.base};
          h3, p, label, a {
            color: #FFFFFF;
          }
        `
      default:
        return css`
          background-color: ${gray[100]};
          h3, p, label, a {
            color: #161616;
          }
        `
    }
  }};

  ${({ $sentStatus }: NewslatterFormProps) => $sentStatus && css`
    background-color: ${white} !important;
    h3, p, label, a {
      color: #161616 !important;
    }
  `}
`
