import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'
import { orange, grayscale, gray } from 'src/styles/colors'

export const CategoryHeader = styled.section`
  align-items: flex-end;
  background: ${orange.base};
  min-height: 150px;

  @media (min-width: ${breakpoints.lg}) {
    min-height: 225px;
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 295px;
  }
`

export const Banner = styled.section<{ backgroundImage: string }>`
  align-items: initial;
  background: ${orange.base};
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom right;
  min-height: 230px;
  background-image: none;

  @media (min-width: ${breakpoints.md}) {
    background-position: top right;
    min-height: 230px;
    align-items: center;
    background-size: 350px 100%;
    background-image: url(${(props: { backgroundImage: string }) => props.backgroundImage});
  }

  @media(min-width: ${breakpoints.lg}) {
    min-height: 330px;
    background-size: 510px 100%;
  }

  @media (min-width: ${breakpoints.xl}) {
    min-height: 460px;
    background-size: 600px 100%;
  }

  @media (min-width: 1440px) {
    min-height: 460px;
    background-size: 720px 100%;
  }

  h1 {
    height: 142px;
    overflow: hidden;

    @media (min-width: ${breakpoints.md}) {
      height: auto;
    }
  }
`

export const ArticleContent = styled.article`
  margin: 35px 0;

  @media (min-width: ${breakpoints.lg}) {
    margin: 50px 0;
  }

  h2,
  h3,
  h4,
  h5 {
    font-weight: 600;
    color: ${grayscale[500]};
  }

  h2 {
    font-size: 22px;
    margin-bottom: 16px;

    @media (min-width: ${breakpoints.lg}) {
      font-size: 26px;
      margin-bottom: 24px;
    }
  }

  h3 {
    font-size: 20px;
    margin-bottom: 14px;
    margin-top: 2.5rem;

    @media (min-width: ${breakpoints.lg}) {
      font-size: 22px;
      margin-bottom: 1rem;
    }
  }

  h4 {
    font-size: 16px;
    margin-bottom: 12px;

    @media (min-width: ${breakpoints.lg}) {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }

  h5 {
    font-size: 14px;
    margin-bottom: 10px;

    @media (min-width: ${breakpoints.lg}) {
      font-size: 16px;
      margin-bottom: 18px;
    }
  }

  li {
    font-size: 16px;
  }

  figcaption {
    font-size: 14px;
    background-color: ${grayscale[100]};
    padding: 8px 16px;
    color: ${gray[800]};
  }
`

export const ShareBar = styled.div`
  border-top: 1px solid ${grayscale['200']};
  color: ${grayscale['400']};
  font-size: 16px;
  padding: 15px 0;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.sm}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`
export const BlockSection = styled.div`
  margin-bottom: 20px;
  
  @media (min-width: ${breakpoints.sm}) {
    margin-bottom: 30px;
  }

  .table-content {
    table {
      cursor: pointer;

      tbody {
        tr {
          height: 90px !important;
        }

        tr:nth-child(2n) {
          background: #f5f6fa;
        }

        td {
          min-width: 121px;
          text-align: center;
          font-size: 16px;
          line-height: 19px;
          padding: 8px;

          & + td {
            border-left: 2px solid #fff;
          }
        }

        .s2,
        .s0,
        .s7,
        .s10 {
          font-weight: 600;
        }
      }
    }
  }
`
export const RelatedNews = styled.section`
  .titulo {
    font-family: 'Sora';
  }
`
