import styled from 'styled-components'
import breakpoints from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'

export const AuthorSection = styled.section`
  padding: 40px 0;

  .title {
    font-family: 'Sora';
  }
`
export const ImgDiv = styled.div`
  flex-shrink: 0;
  img {
    object-fit: cover;
    border-radius: 50%;
    margin-right: 12px;
    width: 42px;
    height: 42px;
    @media (min-width: ${breakpoints.md}) {
      margin-right: 26px;
      width: 72px;
      height: 72px;
    }
    @media (min-width: ${breakpoints.lg}) {
      margin-right: 18px;
      width: 78px;
      height: 78px;
    }
  }
`

export const LinkedinCTA = styled.a`
  width: 190px;
  border-radius: 8px;
  border: 1px solid #FFB46E;
  padding: 8px 16px;
  :hover {
    text-decoration: none;
    color: ${orange.base};
    opacity: .8;
  }
`
