import React, { useEffect, useRef } from 'react'
import { useLocation } from '@reach/router'

import ZoomableImage from 'src/components/ZoomableImage'
import { Post } from 'src/types/Post'
import { DataLayerProps, useDataLayer } from 'src/hooks/useDataLayer'

type TextImageProps = {
  slug: string;
  title: string;
  text: string;
  image_url: string;
  image_caption: string;
  image_alignment: string;
  image_alt?: string;
  scroll: number;
  activeSlug?: string;
  setActiveSlug: (value: string) => void;
  zoomEnabled: boolean;
  redirectUrl: string;
  category?: string;
  subCategory?: string;
  pageContext: Post;
}

const TextImage = ({
  category, subCategory, pageContext, slug, title, text, image_url, image_caption, image_alignment,
  image_alt, scroll, activeSlug, setActiveSlug, zoomEnabled, redirectUrl,
}: TextImageProps) => {
  const textImageRef = useRef<HTMLDivElement>(null)
  const { sendDataLayer }: { sendDataLayer: (data: DataLayerProps) => void } = useDataLayer()
  const location = useLocation()

  useEffect(() => {
    if (title) {
      const offset = (textImageRef.current as HTMLDivElement).getBoundingClientRect().top - document.body.getBoundingClientRect().top
      if ((offset - 70) <= scroll) {
        activeSlug && setActiveSlug(slug)
      }
    }
  }, [ scroll ])

  const dataLayarImage = () => {
    if (image_alt === undefined) {
      return image_caption
    }
  }
  const dataLayerExtract = () => {
    sendDataLayer({
      event: 'ga_event_body_blog',
      params: {
        section: pageContext.title,
        sub_section: title,
        element_action: 'click banner',
        element_name: image_alt || image_caption,
        c_page: location.href,
        redirect_url: redirectUrl || 'null',
        segment: category || 'null',
        sub_segment: subCategory || 'null',
      },
    })

    dataLayer.push({
      event: 'Clicou em foto',
      foto: dataLayarImage(),
    })
  }

  const image = redirectUrl
    ? <a href={redirectUrl}><img className='w-100' loading='lazy' src={image_url} alt={image_alt} title={image_caption} onClick={dataLayerExtract} /></a>
    : <img loading='lazy' className='w-100' src={image_url} alt={image_alt} title={image_caption} onClick={dataLayerExtract} />

  return (
    <div ref={textImageRef} id={slug} className='row my-0'>
      <div className={`col-12 col-lg-6 ${image_alignment === 'right' ? 'order-1' : 'order-0'}`}>
        <figure>
          {zoomEnabled ? (
            <ZoomableImage zoomSrc={image_url} zoomAlt={image_alt} zoomMaxWidth='1200px'>
              {image}
            </ZoomableImage>
          ) : (
            <>{image}</>
          )
          }
          {image_caption && <figcaption className='fs-14 lh-17 bg-grayscale--100 py-2 px-3 text-gray--800'>{image_caption}</figcaption>}
        </figure>
      </div>
      <div className='col-12 col-lg-6'>
        {title && <h2>{title}</h2>}
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  )
}

export default TextImage
