import React, { useEffect } from 'react'
import { useLocation } from '@reach/router'

import MediaStyle from './style'
import ZoomableImage from 'src/components/ZoomableImage'
import { Post } from 'src/types/Post'
import { DataLayerProps, useDataLayer } from 'src/hooks/useDataLayer'

type MediaProps = {
  type: string;
  url: string;
  caption: string;
  alt?: string;
  maxWidth?: string;
  zoomEnabled: boolean;
  redirectUrl: string;
  category?: string;
  subCategory?: string;
  pageContext: Post;
  title: string;
}

const Media = ({
  type,
  url,
  caption,
  alt,
  maxWidth,
  zoomEnabled,
  redirectUrl,
  category,
  subCategory,
  pageContext,
  title,
}: MediaProps) => {
  const {
    sendDataLayer,
  }: { sendDataLayer: (data: DataLayerProps) => void } = useDataLayer()
  const location = useLocation()

  const dataLayarImage = () => {
    if (alt === undefined) {
      return caption
    }
  }
  const dataLayerExtract = () => {
    if (redirectUrl) {
      sendDataLayer({
        event: 'ga_event_body_blog',
        params: {
          section: pageContext.title,
          sub_section: title,
          element_action: 'click banner',
          element_name: alt || caption,
          c_page: location.href,
          redirect_url: redirectUrl || 'null',
          segment: category || 'null',
          sub_segment: subCategory || 'null',
        },
      })
    }
    dataLayer.push({
      event: 'Clicou em foto',
      foto: dataLayarImage(),
    })
  }

  const handleClick = () => {
    if (document?.activeElement?.tagName === 'IFRAME') {
      sendDataLayer({
        event: 'ga_event_body_blog',
        params: {
          section: pageContext.title,
          sub_section: title,
          element_action: 'click video',
          element_name: alt || caption,
          c_page: location.href,
          redirect_url: redirectUrl || 'null',
          segment: category || 'null',
          sub_segment: subCategory || 'null',
        },
      })
    }
  }

  useEffect(() => {
    window.addEventListener('blur', handleClick)

    return () => {
      window.removeEventListener('blur', handleClick)
    }
  }, [])

  const image = redirectUrl ? (
    <a href={redirectUrl}>
      <img
        className='w-100'
        src={url}
        alt={alt}
        title={caption}
        style={{ maxWidth }}
        onClick={dataLayerExtract}
      />
    </a>
  ) : (
    <img
      className='w-100'
      loading='lazy'
      width='100%'
      height='100%'
      src={url}
      alt={alt}
      title={caption}
      style={{ maxWidth }}
      onClick={dataLayerExtract}
    />
  )

  return (
    <MediaStyle style={{ maxWidth }}>
      {type === 'image' ? (
        zoomEnabled ? (
          <ZoomableImage zoomSrc={url} zoomAlt={alt} zoomMaxWidth='1200px'>
            {image}
          </ZoomableImage>
        ) : (
          <>{image}</>
        )
      ) : (
        <iframe
          src={url.replace('youtube.com', 'youtube-nocookie.com')}
          className='optanon-category-C0003'
          frameBorder='0'
          allow='autoplay'
          encrypted-media
          width='100%'
          allowFullScreen
          style={{
            maxWidth,
          }}
        />
      )}
      {caption && <figcaption style={{ maxWidth }}>{caption}</figcaption>}
    </MediaStyle>
  )
}

export default Media
