import React from 'react'

import HowToStructuredData from './HowToStructuredData'

import { PostSeo, PostSeoTopics } from 'src/types/Post'

import { List } from './style'

type HowToProps = {
  data: PostSeo;
  slug?: string;
}

const HowTo = ({ data, slug }: HowToProps) => {
  const listComponent = data.content

  return (
    <>
      <HowToStructuredData data={data} />
      <div className='row'>
        <div className='col-12'>
          <h3 id={slug}>{listComponent.title}</h3>
          <p>{listComponent.description}</p>
          <List>
            {listComponent.topics.map((topic: PostSeoTopics, index: number) => (
              <li key={index}>{topic.text}</li>
            ))}
          </List>
        </div>
      </div>
    </>
  )
}

export default HowTo
