import React, { useState, ChangeEvent } from 'react'
import { useLocation } from '@reach/router'

import { useForm } from 'react-hook-form'

import { sendCDPFormData } from 'src/shared/cdp'

import Message from './Message'

import { Button } from 'src/styles/button'
import { Form, NewsletterForm } from './style'
import { sendNewsletterArticleFrom } from 'src/shared/helpers'
import { DataLayerProps, useDataLayer } from 'src/hooks/useDataLayer'
import { PostForm } from 'src/types/Post'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'

interface IFormNewsletter {
  formData: PostForm;
  dataLayer: {
    section: string;
    category: string;
    subCategory: string;
  };
  articleSlug: string;
  slug: string;
}

const FormNewsletter = ({ formData, dataLayer, articleSlug, slug }: IFormNewsletter) => {
  const { register, errors, handleSubmit, setValue }: Pick<ReturnType<typeof useForm>, 'register' | 'errors' | 'handleSubmit' | 'setValue'> = useForm()
  const [ loading, setLoading ] = useState(false)
  const [ acceptedTerms, setAcceptedTerms ] = useState(false)
  const [ adicionalCheckbox, setAdicionalCheckbox ] = useState(false)
  const [ status, setStatus ] = useState('')
  const { sendDataLayer }: { sendDataLayer: (data: DataLayerProps) => void } = useDataLayer()
  const location = useLocation()
  function handleCheck (event: ChangeEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.checked
    setAcceptedTerms(inputValue)
  }

  function handleCheckAdicionalCheckbox (event: ChangeEvent<HTMLInputElement>) {
    const inputValue = event.currentTarget.checked
    setAdicionalCheckbox(inputValue)
  }

  const sendForm = async (data: Record<string, string>) => {
    setLoading(true)

    const success = await sendNewsletterArticleFrom(data.nomeCompleto, data.cpf, data.email, formData.content.campaign, articleSlug)

    if (success) {
      setStatus('success')
      sendCDPFormData({
        formName: 'newsletter-blog',
        cpf: '',
        email: data.email,
        categoria: dataLayer.category,
        subCategoria: dataLayer.subCategory,
        tituloPagina: document.title,
      })
    } else {
      setStatus('error')
    }
    setLoading(false)

    sendDataLayer({
      event: 'ga_event_body_blog',
      params: {
        section: dataLayer.section || 'null',
        sub_section: 'Assine nossa newsletter',
        element_action: 'submit',
        element_name: 'Assine nossa newsletter',
        c_page: location.href,
        redirect_url: 'null',
        segment: dataLayer.category || 'null',
        sub_segment: dataLayer.subCategory || 'null',
      },
    })
  }

  console.log('checkbox Optional: ', (adicionalCheckbox && !acceptedTerms) || loading)

  return (
    <NewsletterForm theme={formData.content.theme} $sentStatus={status !== ''} id={slug}>
      {status !== ''
        ? <Message status={status} setStatus={setStatus} theme={formData.content.theme} />
        : (
          <Form onSubmit={handleSubmit(sendForm)} theme={formData.content.theme}>

            <div className='container'>
              <div className='row'>
                <div className='col-12 mb-4'>
                  <h3 className='fw-600 mb-2 mt-0'>{formData.content.title}</h3>
                  <p className='mb-0'>
                    {formData.content.description}
                  </p>
                </div>
                <div className='col-12 col-md-4 mb-3 md-md-0'>
                  <label htmlFor='nomeCompleto' className='fs-14 w-100 mb-1 text-left'>Nome</label>
                  <input
                    ref={register({
                      required: 'O nome é obrigatório',
                    })}
                    name='nomeCompleto'
                    id='nomeCompleto'
                    type='text'
                    placeholder='Digite seu nome completo'
                  />
                  { errors.nomeCompleto && <span className='fs-12 text-red-base my-2'>{errors.nomeCompleto.message}</span> }
                </div>
                <div className='col-12 col-md-4 mb-3 md-md-0'>
                  <label htmlFor='cpf' className='fs-14 w-100 mb-1 text-left'>CPF</label>
                  <input
                    ref={register({
                      required: 'CPF obrigatório',
                      validate: {
                        isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                      },
                    })}
                    name='cpf'
                    id='cpf'
                    type='text'
                    placeholder='Digite seu CPF'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                  />
                  { errors.cpf && <span className='fs-12 text-red-base my-2'>{errors.cpf.message}</span> }
                </div>
                <div className='col-12 col-md-4 mb-3 md-md-0'>
                  <label htmlFor='email' className='fs-14 w-100 mb-1 text-left'>E-mail</label>
                  <input
                    ref={register({
                      required: 'E-mail obrigatório',
                      pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: 'E-mail inválido',
                      },
                    })}
                    name='email'
                    id='email'
                    type='text'
                    placeholder='Digite seu email'
                  />
                  { errors.email && <span className='fs-12 text-red-base my-2'>{errors.email.message}</span> }
                </div>
                <div className='col-12 checkbox mb-3'>
                  <input
                    ref={register()}
                    id={`article-form-newsletter-${formData.content.theme}}`}
                    name={`article-form-newsletter-${formData.content.theme}}`}
                    type='checkbox'
                    onChange={(event: ChangeEvent<HTMLInputElement>) => handleCheck(event)}
                    className=''
                  />
                  <label className='formLabelCheck fs-12 lh-16 text-grayscale-400' htmlFor={`article-form-newsletter-${formData.content.theme}}`}>
                    Autorizo o Inter a tratar meus dados pessoais para envio de comunicações sobre os produtos e serviços e estou de acordo com a
                    <a
                      className='fw-700' title='Acessar Política de Privacidade do Banco Inter'
                      href='https://inter.co/politica-de-privacidade/privacidade/'
                      target='_blank' rel='noreferrer'
                      onClick={() => {
                        sendDataLayer({
                          event: 'ga_event_body_blog',
                          params: {
                            section: 'dobra_06',
                            sub_section: 'Assine nossa newsletter',
                            element_action: 'click button',
                            element_name: 'Política de Privacidade',
                            c_page: location.href,
                            redirect_url: 'https://inter.co/politica-de-privacidade/privacidade/',
                            segment: 'null',
                            sub_segment: 'null',
                          },
                        })
                      }}
                    > Política de Privacidade
                    </a> do Inter.
                  </label>
                </div>
                {formData.content.additionalCheckbox && (
                  <div className='col-12 checkbox mb-3'>
                    <input
                      ref={register()}
                      id={`article-form-newsletter-adicional-checkbox-${formData.content.theme}}`}
                      name={`article-form-newsletter-adicional-checkbox-${formData.content.theme}}`}
                      type='checkbox'
                      onChange={(event: ChangeEvent<HTMLInputElement>) => handleCheckAdicionalCheckbox(event)}
                      className=''
                    />
                    <label
                      className='formLabelCheck fs-12 lh-16 text-grayscale-400'
                      htmlFor={`article-form-newsletter-adicional-checkbox-${formData.content.theme}}`}
                    >
                      {formData.content.additionalCheckboxText}
                    </label>
                  </div>
                )}
                <div className='col-12 w-100'>
                  {formData.content.additionalCheckbox ? (
                    <Button
                      type='submit'
                      title={formData.content.buttonText}
                      disabled={(!adicionalCheckbox || !acceptedTerms) || loading}
                      className={`w-100 fw-700 ${formData.content.theme === 'neutral' ? null : 'inverse'}`}
                    >
                      {loading ? 'Enviando...' : formData.content.buttonText}
                    </Button>
                  ) : (
                    <Button
                      type='submit'
                      title={formData.content.buttonText}
                      disabled={!acceptedTerms || loading}
                      className={`w-100 fw-700 ${formData.content.theme === 'neutral' ? null : 'inverse'}`}
                    >
                      {loading ? 'Enviando...' : formData.content.buttonText}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </Form>
        )}
    </NewsletterForm>
  )
}

FormNewsletter.defaultProps = {
  name: 'aceite',
}

export default FormNewsletter
