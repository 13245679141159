import styled from 'styled-components'
import { borderRounded } from 'src/styles/radius'

export const Modal = styled.div`
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
`

export const ContentModal = styled.div`
  border-radius: ${borderRounded['16']};
  padding: 30px 24px 24px 24px;
  position: fixed;
  z-index: 1000;

  max-width: 773px;

  .close-modal {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 2px;
    right: 17px;
    cursor: pointer;
  }

  img {
    border-radius: 16px;
  }
`
