import React from 'react'

import { Btn } from './style'
import { DataLayerProps, useDataLayer } from 'src/hooks/useDataLayer'
import { useLocation } from '@reach/router'
import { Post } from 'src/types/Post'

type ButtonProps = {
  path: string;
  title: string;
  target: boolean;
  pageContext: Post;
  category?: string;
  subCategory?: string;
}

const Button = ({
  path,
  title,
  target,
  pageContext,
  category,
  subCategory,
}: ButtonProps) => {
  const {
    sendDataLayer,
  }: { sendDataLayer: (data: DataLayerProps) => void } = useDataLayer()
  const location = useLocation()

  const handleClick = () => {
    sendDataLayer({
      event: 'ga_event_body_blog',
      params: {
        section: pageContext.title,
        sub_section: title,
        element_action: 'click button',
        element_name: title,
        c_page: location.href,
        redirect_url: path || 'null',
        segment: category || 'null',
        sub_segment: subCategory || 'null',
      },
    })
  }

  return target ? (
    <Btn
      id='cta-blog'
      href={path}
      title={title}
      className='botao-orange'
      target='_blank'
      onClick={handleClick}
    >
      {title}
    </Btn>
  ) : (
    <Btn
      id='cta-blog'
      href={path}
      title={title}
      className='botao-orange'
      onClick={handleClick}
    >
      {title}
    </Btn>
  )
}

export default Button
