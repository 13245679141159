import styled from 'styled-components'
import { orange } from 'src/styles/colors'

export default styled.nav`
    flex-wrap: wrap;

  svg {
    margin: 0 5px;
  }

  a {
    opacity: 1;
    text-decoration: none;
    font-weight: 300;

    &:last-child {
      font-weight: 700;
    }

    &:hover {
      color: ${orange.base};
    }
  } 

  span {
    display: flex;

    &:nth-child(3) {
      display: flex;
    }

    &:nth-child(4) {
      display: block !important;
    }
  }
`
