import React from 'react'

import { PopUpDiv } from './style'
import { Post, PostPopUp } from 'src/types/Post'
import { DataLayerProps, useDataLayer } from 'src/hooks/useDataLayer'
import { useLocation } from '@reach/router'
interface IPostsPopup {
  popUp: PostPopUp;
  articleSlug: string;
  pageContext?: Post;
  category: string;
  subCategory: string;
}

const PostsPopup = ({ popUp, articleSlug, pageContext, category, subCategory }: IPostsPopup) => {
  const { sendDataLayer }: { sendDataLayer: (data: DataLayerProps) => void } = useDataLayer()
  const location = useLocation()

  console.log('tstesnndsnjdnsjknds: ' + popUp?.link)

  const dataLayerExtract = () => {
    sendDataLayer({
      event: 'ga_event_body_blog',
      params: {
        section: pageContext?.title as string,
        sub_section: pageContext?.title as string,
        element_action: 'click popup',
        element_name: popUp?.title,
        c_page: location.href,
        redirect_url: popUp?.link || 'null',
        segment: category || 'null',
        sub_segment: subCategory || 'null',
      },
    })

    return dataLayer.push({
      event: 'pop-up open',
      content_name: `modal-popup-${articleSlug}`,
      content_type: 'on-scroll',
    })
  }

  return (
    <PopUpDiv className='container px-0 px-lg-3'>
      <div className='row justify-content-between align-items-center' onClick={dataLayerExtract}>
        <div className='col-12 px-0'>
          <div className='w-100 d-flex align-items-center justify-content-center'>
            <a href={popUp?.link} target='_blank' rel='noreferrer'>
              <img src={popUp?.image?.url} alt={popUp?.image?.alt} />
            </a>
          </div>
        </div>
      </div>
    </PopUpDiv>
  )
}

export default PostsPopup
