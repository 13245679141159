import styled, { css } from 'styled-components'
import { orange } from 'src/styles/colors'
import { Theme } from 'src/types/Theme'

type ScrollButtonStyleProps = {
  position: string;
  theme: Theme;
}

export default styled.button<ScrollButtonStyleProps>`
  border: none;
  background-color: ${({ theme }: ScrollButtonStyleProps) => theme === 'pj' ? 'rgb(230, 231, 240)' : orange[100]};
  border-radius: 100%;
  height: 54px;
  width: 54px;
  display: block;
  outline: none !important;

  ${(props: ScrollButtonStyleProps) => {
    switch (props.position) {
      case 'relative':
        return css`
          position: relative;
          margin: 150px auto 0;
        `
      case 'absolute':
        return css`
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        `
    }
  }}
`
