import React, { useEffect, useRef, useState } from 'react'
import useDomReady from 'src/hooks/useDomReady'
import PostsPopup from '../PostsPopup'

import Icon from 'src/components/Icon'

import popUpData from 'src/assets/data/popup/popups.json'

import { Post, PostPopUp } from 'src/types/Post'
import { Modal, ContentModal } from './style'
import useSessionStorage from 'src/hooks/useSessionStorage'

interface IPopUpContainer {
  popUp: string;
  articleSlug: string;
  pageContext?: Post;
  category?: string;
  subCategory?: string;
}

export type SelectedProps = {
  selected: PostPopUp[] | undefined;
}

const PopUpContainer = ({ popUp, articleSlug, pageContext, category, subCategory }: IPopUpContainer) => {
  const domReady = useDomReady()
  const [ filteredPopUp, setFilteredPopUp ] = useState<PostPopUp[]>()
  const popupJson: PostPopUp[] = popUpData
  const prevScrollY = useRef(0)
  const [ openModal, setOpenModal ] = useState(false)
  const [ openExitModal, setOpenExitModal ] = useState(false)
  const [ popupSessionStorage, setPopupSessionStorage ] = useSessionStorage(`@hide-bancointer-popup-${articleSlug}`, false)
  const [ popupType, setPopupType ] = useState<string | undefined>('')
  const [ updateDates, setUpdateDates ] = useState<PostPopUp[] | undefined>([])

  useEffect(() => {
    setFilteredPopUp(popupJson.filter((data: PostPopUp) => data))
  }, [ popUp ])

  const isPost = domReady ? window.location.pathname.replaceAll('/', '') : ''

  const updateDate = (): PostPopUp[] | undefined => {
    const isUpdateNews = filteredPopUp?.filter((item: PostPopUp) => {
      return item.news.includes(isPost)
    })

    const orderedNews =
      isUpdateNews?.sort((article1: PostPopUp, article2: PostPopUp) => new Date(article2.startDate).getTime() - new Date(article1.startDate).getTime())
    orderedNews && setPopupType(orderedNews[0].type)
    return orderedNews
  }

  useEffect(() => {
    setUpdateDates(updateDate())
  }, [ filteredPopUp ])

  const closeModal = () => {
    setOpenModal(false)
    setOpenExitModal(false)
    setPopupSessionStorage(true)
  }

  // popup saida
  const handleOnMouseLeave = () => {
    console.log('HANDLE_ON_MOUSE: ', popupType)
    setOpenExitModal(true)
  }

  useEffect(() => {
    if (popupType === 'saida') {
      document.body.addEventListener('mouseleave', handleOnMouseLeave)

      return () => document.body.addEventListener('mouseleave', handleOnMouseLeave)
    }
  }, [ popupType ])

  useEffect(() => {
  }, [ popupType ])

  // popup on scroll
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY
      prevScrollY.current = currentScrollY

      if (window.scrollY > 700) {
        setOpenModal(true)
      }
    }

    if (popupType === 'scroll') {
      window.addEventListener('scroll', handleScroll, { passive: true })

      return () => window.removeEventListener('scroll', handleScroll)
    }
  }, [ popupType ])

  return (
    <>
      { popupType === 'saida' ? (
        !popupSessionStorage && openExitModal &&
          <>
            <Modal onClick={closeModal} className='d-flex justify-content-center align-items-center'>
              <ContentModal className='d-flex justify-content-center align-items-center'>
                <div className='close-modal d-flex align-items-center justify-content-center' onClick={closeModal}>
                  <Icon width='24' height='24' directory='v2' icon='navigation/close' color='white' />
                </div>
                {filteredPopUp && updateDates &&
                  <PostsPopup
                    popUp={updateDates && updateDates[0]} articleSlug={articleSlug}
                    pageContext={pageContext}
                    category={category}
                    subCategory={subCategory}
                  />}
              </ContentModal>
            </Modal>
          </>
      ) : (
        !popupSessionStorage && openModal &&
          <>
            <Modal onClick={closeModal} className='d-flex justify-content-center align-items-center'>
              <ContentModal className='d-flex justify-content-center align-items-center'>
                <div className='close-modal d-flex align-items-center justify-content-center' onClick={closeModal}>
                  <Icon width='24' height='24' directory='v2' icon='navigation/close' color='white' />
                </div>
                {filteredPopUp && updateDates &&
                  <PostsPopup
                    popUp={updateDates && updateDates[0]} articleSlug={articleSlug}
                    pageContext={pageContext}
                    category={category}
                    subCategory={subCategory}
                  />}
              </ContentModal>
            </Modal>
          </>
      )}
    </>
  )
}

export default PopUpContainer
