import React, { useEffect, useState, useMemo, useRef } from 'react'
import { PageProps } from 'gatsby'
import { format, parseISO } from 'date-fns'
import slugify from 'slugify'
import { FacebookProvider } from 'react-facebook'
import { Helmet } from 'react-helmet'
import { useLocation } from '@reach/router'
// Os imports comentados abaixo, referem-se aos formulários que foram ocultados até segunda ordem.
import Layout from 'src/components/Layout'
import Tag from 'src/components/Tag/index'
import BreadCrumb from 'src/components/Breadcrumb'
// import Newsletter from 'src/components/Newsletter'
// import FormLeads from 'src/components/FormLeads'
import Text from 'src/components/ContentBlocks/Text'
import Media from 'src/components/ContentBlocks/Media'
import PdfFile from 'src/components/ContentBlocks/PDF'
import TextImage from 'src/components/ContentBlocks/TextImage'
import FAQ from 'src/components/ContentBlocks/FAQ'
import Citation from 'src/components/ContentBlocks/Citation'
import BlogArticle from 'src/components/BlogArticle'
import ScrollButton from 'src/components/ScrollButton'
import AddThis from 'src/components/AddThis'
import Button from 'src/components/ContentBlocks/button'
import QuickAccess from 'src/components/QuickAccess'
import TweetEmbed from 'react-tweet-embed'
import HowTo from 'src/components/ContentBlocks/HowTo'
import AuthorComponent from 'src/components/AuthorSection'

import useScroll from 'src/hooks/useScroll'
// import useWidth from 'src/hooks/useWidth'

import categoriesJSON from 'src/assets/data/categories.json'
import authorsJSON from 'src/assets/data/author/autores.json'
import linksUnshortedList from 'src/assets/data/unshort-links-list.json'

import {
  Post,
  PostText,
  PostVideo,
  PostPdf,
  PostTextImage,
  PostImage,
  PostSubText,
  TweetPost,
  PostFaq,
  RelatedPost,
  PostCitation,
  PostButton,
  PostSeo,
  PostStructuredVideo,
  PostForm,
} from 'src/types/Post'
import { Author } from 'src/types/Author'
import { Category, SubCategory } from 'src/types/Category'

import { showUpdateTime } from 'src/shared/helpers'

import { SideBar /* NewsletterContent */ } from 'src/styles/layout'
import {
  Banner,
  ArticleContent,
  ShareBar,
  BlockSection,
  RelatedNews,
} from './style'
import SEO from 'src/components/Seo'
import PopUpContainer from 'src/components/PopUpContainer'
import VideoStructuredData from 'src/components/ContentBlocks/VideoObject'
import { DataLayerProps, useDataLayer } from 'src/hooks/useDataLayer'
import FormNewsletter from 'src/components/ContentBlocks/FormNewsletter'

type AllPostsTypes =
  | PostText
  | PostVideo
  | PostTextImage
  | PostPdf
  | PostImage
  | PostSubText
  | PostFaq
  | PostCitation
  | PostButton
  | TweetPost
  | PostSeo
  | PostStructuredVideo
  | PostForm

type ArticleProps = {
  pageContext: Post;
} & PageProps

const generateBlogPostSchema = (postData: Post) => {
  return JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'BlogPosting',
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': `${process.env.BASE_URL}/${postData.slug}`,
    },
    headline: postData.title,
    description: postData.introduction,
    image: [ postData.grid_image_url, postData.banner_image_url ],
    author: {
      '@type': 'Organization',
      name: 'Inter',
    },
    publisher: {
      '@type': 'Organization',
      name: 'Inter',
      logo: {
        '@type': 'ImageObject',
        url:
          'https://marketing.bancointer.com.br/comunicacao/e-mail-mkt-institucional/imagens/banco-inter.png',
      },
    },
    datePublished: postData.published_at,
    dateModified: postData.updated_at,
  })
}

const Article = ({ pageContext }: ArticleProps) => {
  const scroll = useScroll(300)
  // const width = useWidth()
  const category = categoriesJSON.find(
    (category: Category) => category.id === pageContext.category_id,
  )
  const subCategory =
    category?.subcategories &&
    category.subcategories.find(
      (subCategory: SubCategory) =>
        subCategory.id === pageContext.subcategory_id,
    )
  const author = authorsJSON.find(
    (columnist: Author) => columnist.id === pageContext.author_id,
  )

  const [ activeSlug, setActiveSlug ] = useState<string>()

  const {
    sendDataLayer,
  }: { sendDataLayer: (data: DataLayerProps) => void } = useDataLayer()
  const location = useLocation()

  const breadcrumbPath = [
    {
      link: '/',
      text: 'Blog',
    },
    {
      link: `/${(category as Category)?.slug}`,
      text: (category as Category)?.name,
    },
  ]

  if (category) {
    if (category.subcategories) {
      if (subCategory) {
        const path = [
          {
            link: `/${(subCategory as SubCategory)?.slug}`,
            text: subCategory?.name,
          },
          {
            link: '',
            text: pageContext.title,
          },
        ]
        breadcrumbPath.push(...path)
      } else {
        breadcrumbPath.push({
          link: '',
          text: pageContext.title,
        })
      }
    }
  }

  // function leadsDecider () {
  //   const location = useLocation()
  //   return location.pathname.includes('/mitos-e-verdades-consorcio')
  //     ? 'consorcio'
  //     : location.pathname.includes('/desvendando-a-previdencia-privada')
  //       ? 'previdencia'
  //       : 'newsletter'
  // }

  useEffect(() => {
    const firstContentWithTitle = pageContext.content_blocks.find(
      (contentBlock: AllPostsTypes) =>
        (contentBlock as PostText | PostTextImage).content.title !==
        'undefined',
    )
    if (firstContentWithTitle.content.title) {
      const slug = slugify(
        firstContentWithTitle.content.title.replace(/\./g, ''),
        { lower: true },
      )
      setActiveSlug(slug)
    }
  }, [])

  const postSchema = useMemo(() => {
    return generateBlogPostSchema(pageContext)
  }, [ pageContext.slug ])

  const handleClick = (event: any) => {
    if (event.target.tagName === 'INS' || event.target.tagName === 'A' || event.target.tagName === 'IMG') {
      let currentNode = event.target
      let h2Content = null

      if (event.target.tagName === 'IMG' && event.target.parentElement.tagName !== 'A') {
        return
      }

      while (currentNode) {
        if (currentNode.tagName === 'H2') {
          h2Content = currentNode.textContent
          break
        }
        currentNode =
          currentNode.previousElementSibling || currentNode.parentElement
      }

      const link = event.target.tagName === 'A'
        ? event.target.href
        : event.target.tagName === 'IMG'
          ? event.target.parentNode.href
          : event.target.parentNode.href

      const longLink = linksUnshortedList.find((item: any) => item.key === link)?.value

      sendDataLayer({
        event: 'ga_event_body_blog',
        params: {
          section: pageContext.title,
          sub_section: h2Content,
          element_action: event.target.tagName === 'IMG' ? 'click banner' : 'click button',
          element_name:
            event.target.tagName === 'A'
              ? event.target.innerText
              : event.target.tagName === 'IMG'
                ? event.target.alt
                : event.target.parentNode.innerText,
          c_page: location.href,
          redirect_url: longLink ?? link,
          segment: (category as Category)?.name,
          sub_segment: (subCategory as SubCategory)?.name || 'null',
        },
      })
    }
  }

  useEffect(() => {
    window.addEventListener('click', handleClick)

    return () => {
      window.removeEventListener('click', handleClick)
    }
  }, [])

  return (
    <FacebookProvider appId={process.env.FACEBOOK_APP_ID}>
      <Layout className='menu-bg-orange' category={category && category.slug}>
        <SEO
          title={`${
            pageContext.seo_title || pageContext.title
          } · Blog do Inter`}
          url={`${process.env.BASE_URL}/${pageContext.slug}/`}
          meta={[
            {
              property: 'fb:app_id',
              content: process.env.FACEBOOK_APP_ID,
            },
            {
              name: 'description',
              content:
                pageContext.seo_description ||
                pageContext.og_description ||
                pageContext.introduction,
            },
            {
              property: 'og:title',
              content: `${
                pageContext.og_title ||
                pageContext.seo_title ||
                pageContext.title
              } · Blog do Inter`,
            },
            {
              property: 'og:description',
              content:
                pageContext.og_description ||
                pageContext.seo_description ||
                pageContext.introduction,
            },
            {
              property: 'og:image',
              content:
                pageContext.og_image ||
                pageContext.banner_image_url ||
                pageContext.grid_image_url,
            },
            {
              property: 'og:image:width',
              content:
                pageContext.og_image_width ||
                (pageContext.banner_image_url ? '750' : '370'),
            },
            {
              property: 'og:image:height',
              content:
                pageContext.og_image_height ||
                (pageContext.banner_image_url ? '460' : '260'),
            },
            {
              property: 'og:locale',
              content: 'pt_BR',
            },
            {
              property: 'og:type',
              content: 'article',
            },
            {
              property: 'og:url',
              content: `${process.env.BASE_URL}/${pageContext.slug}`,
            },
            {
              property: 'article:publisher',
              content: 'https://www.facebook.com/bancointer',
            },
            {
              property: 'article:section',
              content: `${category?.name}`,
            },
            {
              property: 'article:published_time',
              content: pageContext.published_at,
            },
            {
              property: 'article:modified_time',
              content: pageContext.updated_at,
            },
            {
              property: 'og:updated_time',
              content: pageContext.updated_at,
            },
            {
              property: 'og:site_name',
              content: 'Blog do Inter',
            },
            {
              property: 'twitter:card',
              content: 'summary',
            },
            {
              property: 'twitter:site',
              content: '@bancointer',
            },
            {
              property: 'twitter:title',
              content:
                pageContext.twitter_title ||
                pageContext.og_title ||
                pageContext.title,
            },
            {
              property: 'twitter:description',
              content:
                pageContext.twitter_description ||
                pageContext.og_description ||
                pageContext.introduction,
            },
            {
              property: 'twitter:creator',
              content: '@bancointer',
            },
            {
              property: 'categoria',
              content: category?.name,
            },
            {
              property: 'subcategoria',
              content: subCategory?.name,
            },
          ]}
        />
        <Helmet>
          <script type='application/ld+json'>{postSchema}</script>
        </Helmet>
        <Banner
          className='d-flex'
          backgroundImage={pageContext.banner_image_url}
          role='img'
          aria-label={pageContext.banner_image_alt || ''}
        >
          <div className='container'>
            <div className='row justify-content-center justify-content-md-start align-items-center'>
              <div className='col-12 col-md-6 pt-3'>
                <div className='d-flex'>
                  <Tag color='white' className='mr-2'>
                    {category?.name}
                  </Tag>
                  {subCategory && <Tag color='white'>{subCategory?.name}</Tag>}
                </div>
                <h1 className='fs-24 lh-28 fs-lg-32 lh-lg-37 fs-xl-40 lh-xl-47 fw-600 text-white mt-3 mb-0 mb-lg-3'>
                  {pageContext.title}
                </h1>
                <span className='fs-12 lh-15 text-white d-block mt-0 mt-md-3'>
                  Publicado em{' '}
                  {format(parseISO(pageContext.published_at), 'dd/MM/yyyy')}
                </span>
                {pageContext.update_date &&
                  showUpdateTime(
                    pageContext.published_at,
                    pageContext.update_date,
                  ) && (
                  (
                    <span className='fs-12 lh-15 text-white mt-2 d-block mb-4 mb-md-0'>
                      Atualizado em{' '}
                      {format(parseISO(pageContext.update_date), 'dd/MM/yyyy')}
                    </span>
                  )
                )}
              </div>
            </div>
          </div>
        </Banner>
        <section
          id='compartilhe'
          className='bg-grayscale--100 py-3 d-flex justify-content-center align-items-center h-breadcrumb'
        >
          <div className='container'>
            <div className='row justify-content-center align-items-center'>
              <div className='col-12 col-lg-9'>
                <BreadCrumb path={breadcrumbPath} />
              </div>
              <div className='col-6 col-lg-3 text-right px-0 pr-lg-2'>
                <AddThis />
              </div>
            </div>
          </div>
        </section>

        <ArticleContent id={pageContext.slug}>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-12 col-lg-7'>
                {pageContext.content_blocks.map(
                  (contentBlock: AllPostsTypes, index: number) => {
                    switch (contentBlock.type) {
                      case 'text':
                      case 'html': {
                        const slug = slugify(
                          (contentBlock as PostText).content.title,
                          { lower: true, strict: true },
                        )
                        return (
                          <BlockSection key={`${pageContext.id}-${index}`}>
                            <Text
                              slug={'ir-para-' + slug}
                              title={(contentBlock as PostText).content.title}
                              text={(contentBlock as PostText).content.text}
                              scroll={scroll}
                              activeSlug={activeSlug}
                              setActiveSlug={setActiveSlug}
                              pageContext={pageContext}
                            />
                          </BlockSection>
                        )
                      }
                      case 'image':
                      case 'video':
                        return (
                          <BlockSection key={`${pageContext.id}-${index}`}>
                            {contentBlock.type === 'video' && (
                              <VideoStructuredData
                                data={
                                  (contentBlock as PostStructuredVideo).content
                                }
                              />
                            )}
                            <Media
                              type={
                                (contentBlock as PostImage | PostVideo).type
                              }
                              url={
                                (contentBlock as PostImage | PostVideo).content
                                  .url
                              }
                              caption={
                                (contentBlock as PostImage | PostVideo).content
                                  .caption
                              }
                              alt={
                                (contentBlock as PostImage | PostVideo).content
                                  .alt
                              }
                              maxWidth={
                                (contentBlock as PostImage | PostVideo).content
                                  .maxWidth
                              }
                              zoomEnabled={
                                (contentBlock as PostImage | PostVideo).content
                                  .image_zoom_enabled
                              }
                              redirectUrl={
                                (contentBlock as PostImage | PostVideo).content
                                  .image_redirectUrl ||
                                (contentBlock as PostImage | PostVideo).content
                                  .redirect
                              }
                              category={(category as Category)?.name}
                              subCategory={(subCategory as SubCategory)?.name}
                              pageContext={pageContext}
                              title={(contentBlock as PostText).content.title}
                            />
                          </BlockSection>
                        )
                      case 'text-image': {
                        const slug = slugify(
                          (contentBlock as PostTextImage).content.title,
                          { lower: true, strict: true },
                        )
                        return (
                          <BlockSection key={`${pageContext.id}-${index}`}>
                            <TextImage
                              category={(category as Category)?.name}
                              subCategory={(subCategory as SubCategory)?.name}
                              pageContext={pageContext}
                              slug={'ir-para-' + slugify(slug)}
                              title={
                                (contentBlock as PostTextImage).content.title
                              }
                              text={
                                (contentBlock as PostTextImage).content.text
                              }
                              image_url={
                                (contentBlock as PostTextImage).content
                                  .image_url
                              }
                              image_caption={
                                (contentBlock as PostTextImage).content
                                  .image_caption
                              }
                              image_alignment={
                                (contentBlock as PostTextImage).content
                                  .image_alignment
                              }
                              scroll={scroll}
                              setActiveSlug={setActiveSlug}
                              activeSlug={activeSlug}
                              zoomEnabled={
                                (contentBlock as PostImage | PostVideo).content
                                  .image_zoom_enabled
                              }
                              redirectUrl={
                                (contentBlock as PostImage | PostVideo).content
                                  .redirect || ''
                              }
                            />
                          </BlockSection>
                        )
                      }
                      case 'pdf': {
                        return (
                          <BlockSection key={`${pageContext.id}-${index}`}>
                            <PdfFile
                              name={(contentBlock as PostPdf).content.name}
                              description={
                                (contentBlock as PostPdf).content.description
                              }
                              file={(contentBlock as PostPdf).content.file}
                            />
                          </BlockSection>
                        )
                      }
                      case 'faq': {
                        const slug = slugify(
                          (contentBlock as PostFaq).content.title,
                          {
                            lower: true,
                            strict: true,
                          },
                        )
                        return (
                          <BlockSection key={`${pageContext.id}-${index}`}>
                            <FAQ
                              title={(contentBlock as PostFaq).content.title}
                              slug={`ir-para-${slug}`}
                              columns={{
                                sm: 1,
                                md: 1,
                                lg: 1,
                                xl: 1,
                              }}
                              data={(contentBlock as PostFaq).content.topics}
                            />
                          </BlockSection>
                        )
                      }
                      case 'citation':
                        return (
                          <BlockSection key={`${pageContext.id}-${index}`}>
                            <Citation
                              author={
                                (contentBlock as PostCitation).content.author
                              }
                              text={(contentBlock as PostCitation).content.text}
                            />
                          </BlockSection>
                        )
                      case 'button': {
                        return (
                          <BlockSection
                            key={`${pageContext.id}-${index}`}
                            className='text-center'
                          >
                            <Button
                              path={(contentBlock as PostButton).content.path}
                              title={(contentBlock as PostButton).content.title}
                              target={
                                (contentBlock as PostButton).content.openNewTab
                              }
                              pageContext={pageContext}
                              category={category?.name}
                              subCategory={subCategory?.name}
                            />
                          </BlockSection>
                        )
                      }
                      case 'tweet': {
                        const arrayUrl = (contentBlock as TweetPost).content.url.split(
                          '/',
                        )
                        const idTweet = arrayUrl[arrayUrl.length - 1]
                        return (
                          <BlockSection key={contentBlock.content.url}>
                            <TweetEmbed
                              id={idTweet}
                              placeholder='Carregando...'
                              options={{ theme: 'dark', align: 'center' }}
                            />
                          </BlockSection>
                        )
                      }
                      case 'seo': {
                        const slug = slugify(
                          (contentBlock as PostSeo).content.title,
                          { lower: true, strict: true },
                        )
                        return (
                          <BlockSection key={`${pageContext.id}-${index}`}>
                            <HowTo
                              data={contentBlock as PostSeo}
                              slug={`ir-para-${slug}`}
                            />
                          </BlockSection>
                        )
                      }
                      case 'form': {
                        const slug = slugify(
                          (contentBlock as PostText).content.title,
                          { lower: true, strict: true },
                        )
                        return (
                          <BlockSection
                            key={`${pageContext.id}-${index}`}
                          >
                            <FormNewsletter
                              slug={`ir-para-${slug}`}
                              formData={contentBlock as PostForm}
                              dataLayer={{
                                section: pageContext.title,
                                category: (category as Category)?.name,
                                subCategory: (subCategory as SubCategory)?.name,
                              }}
                              articleSlug={pageContext.slug}
                            />
                          </BlockSection>
                        )
                      }
                      default:
                        return (
                          <div key={`${pageContext.id}-${index}`}>
                            [Content Not Mapped]
                          </div>
                        )
                    }
                  },
                )}
              </div>
              <div className='d-none d-lg-block col-lg-4'>
                <SideBar>
                  <div>
                    <QuickAccess
                      pageContext={pageContext}
                      category={(category as Category)?.name}
                      subCategory={(subCategory as SubCategory)?.name}
                    />
                    {/* {leadsDecider() === 'newsletter' ? (
                      <NewsletterContent className='w-100'>
                        <span className='titulo fs-22 lh-25 fw-600 mb-3 text-white'>
                          Assine nossa newsletter
                        </span>
                        <p className='fs-14 lh-17 text-white'>
                          Receba conteúdos completos sobre investimentos,
                          educação financeira e novos produtos
                        </p>
                        <Newsletter
                          pageContext={pageContext}
                          name='side-bar'
                          category={(category as Category)?.name}
                          subCategory={
                            subCategory && (subCategory as SubCategory).name
                          }
                        />
                      </NewsletterContent>
                    ) : (
                      width > 991 && (
                        <NewsletterContent>
                          <FormLeads
                            pageContext={pageContext}
                            article={leadsDecider()}
                            name={leadsDecider()}
                            category={(category as Category)?.name}
                            subCategory={
                              subCategory && (subCategory as SubCategory)?.name
                            }
                          />
                        </NewsletterContent>
                      )
                    )} */}
                  </div>
                  <ScrollButton position='absolute' />
                </SideBar>
              </div>
            </div>
          </div>
        </ArticleContent>

        {author && <AuthorComponent author={author} />}

        {/* {leadsDecider() === 'newsletter' ? (
          <NewsletterContent className='d-lg-none w-100'>
            <span className='titulo fs-20 lh-25 fw-600 mb-2 text-white'>
              Assine nossa newsletter
            </span>
            <p className='fs-14 lh-17 text-white'>
              Receba conteúdos completos sobre investimentos, educação
              financeira e novos produtos
            </p>
            <Newsletter
              pageContext={pageContext}
              name='side-bar-mobile'
              category={(category as Category)?.name}
              subCategory={subCategory && (subCategory as SubCategory).name}
            />
          </NewsletterContent>
        ) : (
          width <= 991 && (
            <NewsletterContent className='w-100'>
              <FormLeads
                pageContext={pageContext}
                article={leadsDecider()}
                name={leadsDecider()}
                category={(category as Category)?.name}
                subCategory={subCategory && (subCategory as SubCategory).name}
              />
            </NewsletterContent>
          )
        )} */}

        <div className='container mb-3 mb-lg-0'>
          <ShareBar>
            <div className='mb-2 mb-sm-0'>Gostou? Compartilhe</div>
            <AddThis />
          </ShareBar>
        </div>

        {pageContext.related_posts.length > 0 && (
          <RelatedNews id='assuntos-relactionados' className='py-5'>
            <div className='container'>
              <span className='titulo d-block fs-20 fs-lg-24 fw-600 text-grayscale--500 mb-4'>
                Assuntos relacionados
              </span>
              <div className='row'>
                {pageContext.related_posts.map((relatedPost: RelatedPost) => {
                  const category = categoriesJSON.find(
                    (category: Category) =>
                      category.id === relatedPost.category_id,
                  )
                  const subCategory =
                    category?.subcategories &&
                    category?.subcategories.find(
                      (subCategory: SubCategory) =>
                        subCategory.id === relatedPost.subcategory_id,
                    )
                  return (
                    <div key={relatedPost.slug} className='col-12 col-md-4'>
                      <BlogArticle
                        link={`/${relatedPost.slug}/`}
                        image={relatedPost.grid_image_url}
                        category={(category as Category)?.name}
                        subCategory={subCategory?.name}
                        title={relatedPost.title}
                        date={format(
                          parseISO(relatedPost.updated_at),
                          'dd/MM/yyyy',
                        )}
                        description={relatedPost.introduction}
                      />
                    </div>
                  )
                })}
              </div>
            </div>
          </RelatedNews>
        )}
        {pageContext.popup_id && (
          <PopUpContainer
            popUp={pageContext.popup_id}
            articleSlug={pageContext.slug}
            pageContext={pageContext}
            category={category?.name}
            subCategory={subCategory?.name}
          />
        )}
      </Layout>
    </FacebookProvider>
  )
}

export default Article
