import { Theme } from 'src/types/Theme'
import styled from 'styled-components'
import { orange, white, grayscale, green } from './colors'
import { borderRounded } from './radius'

export const SideBar = styled.aside`
  height: 100%;
  padding: 0 0 80px;

  > div {
    position: sticky;
    top: 90px;
  }
`

type NewsletterContentProps = {
  theme: Theme;
}

export const NewsletterContent = styled.div<NewsletterContentProps>`
  background: ${({ theme }: NewsletterContentProps) => theme === 'pj' ? green[500] : orange.base};
  padding: 25px 20px;
  border-radius: ${borderRounded['3']};

  label, a, h4, p {
    color: ${white} !important;
  }

  .checkbox {

    label {
      &::before {
        border: 2px solid ${white} !important;
      }
    }

    input {

      &:checked ~ label {
        &::before {
          border: 2px solid ${white};
        }
        &::after {
        }
      }
    }
  }

  button {
    background: ${grayscale['500']};
    border: 1px solid ${grayscale['500']};

    &:hover {
      background: ${grayscale['500']};
      opacity: 0.9;

      &:disabled {
        background: ${grayscale['200']};
        opacity: 1;
        cursor: crosshair;
      }
    }
  }

  .titulo {
    display: block;
    font-family: 'Sora';
  }
`
