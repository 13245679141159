import React from 'react'
import { Helmet } from 'react-helmet'

import { Author } from 'src/types/Author'

interface IAuthorComponent {
  author: Author;
}

const AuthorStructuredData = ({ author }: IAuthorComponent) => {
  const structuredData = {
    '@type': 'Person',
    '@id': `${author.pageLink}#schema-author`,
    url: author.pageLink,
    name: author.name,
    image: {
      '@type': 'ImageObject',
      '@id': `${author.pageLink}#author-image`,
      url: author.image.url,
      inLanguage: 'pt-BR',
      caption: author.name,
    },
    jobTitle: author.role,
    description: author.biography,
    sameAs: [ author.linkedIn ],
  }

  return (
    <Helmet>
      <script type='application/ld+json'>{`${JSON.stringify(structuredData)}`}</script>
    </Helmet>
  )
}

export default AuthorStructuredData
