import styled from 'styled-components'
import { orange, gray } from 'src/styles/colors'

export const BoxCitation = styled.div`

  > div:first-child {
    border-left: 2px solid ${orange.base};
    padding: 0 20px;
    align-items: start;
    display: flex;

    h2 {
      color: ${gray['800']};
      font-size: 18px;
      font-family: 'Inter';
    }
  }
  > div:last-child {
    padding-left: 65px;
  }
`
