import React from 'react'
import Icon from '../Icon'

import AuthorStructuredData from './AuthorStructuredData'

import { Author } from 'src/types/Author'

import { AuthorSection, ImgDiv, LinkedinCTA } from './style'

interface IAuthorComponent {
  author: Author;
}

const AuthorComponent = ({ author }: IAuthorComponent) => {
  const linkHasHttp = author.linkedIn.includes('http')
  const linkedinLink = linkHasHttp ? author.linkedIn : `https://${author.linkedIn}`

  return (
    <AuthorSection>
      <AuthorStructuredData author={author} />
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-7 col-lg-6 col-xl-5'>
            <div className='d-flex'>
              <ImgDiv>
                <img src={author.image.url} alt={author.image.url} />
              </ImgDiv>
              <div>
                <span className='title fs-14 lh-18 fw-700 text-grayscale--500 d-block mb-2'>{author.name}</span>
                <span className='fs-14 lh-17 text-grayscale--400 d-block mb-2'>{author.role}</span>
                <p className='fs-14 lh-17 text-grayscale--400 d-block mb-3'>{author.biography}</p>
                <div>
                  <LinkedinCTA href={linkedinLink} target='_blank' className='d-flex justify-content-between'>
                    <span className='fs-10 lh-12 fw-700 text-orange--extra'>
                      Acessar perfil do Linkedin
                    </span>
                    <Icon width='16' height='16' directory='v2' icon='social/new-linkedin' />
                  </LinkedinCTA>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthorSection>
  )
}

export default AuthorComponent
