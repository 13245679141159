import React, { useRef } from 'react'

import { BoxCitation } from './style'

import AspasCitation from './images/aspas.svg'

type TextProps = {
  author: string;
  text: string;
}

const Citation = ({ author, text }: TextProps) => {
  const textRef = useRef<HTMLDivElement>(null)

  return (
    <>
      <BoxCitation ref={textRef} id={author}>
        <div>
          <img src={AspasCitation} alt='Citação' className='mr-3' />
          <h2 className='fw-700 lh-24 mb-0'><div dangerouslySetInnerHTML={{ __html: text }} /></h2>
        </div>
        <div className='pt-2'>{author && <span className='fs-14 lh-17 d-block'> - {author}</span>}</div>
      </BoxCitation>
    </>
  )
}

export default Citation
